<template>
  <div class="personal">
    <Title :title="$t(title)" class="title" />
    <CommonForm />
    <div class="des">
      <div>{{ autoPrefix('01191c5') }}，</div>
      <div class="link" @click="goLogin">{{ autoPrefix('1573214') }}</div>
    </div>
  </div>
</template>

<script>
import CommonForm from '../components/CommonForm'
import Title from '@/components/Title'
export default {
  name: 'Personal',
  components: {
    CommonForm,
    Title,
  },
  data() {
    return {
      title: '22d91aa',
    }
  },
  computed: {
    abroad() {
      return this.$route.query.abroad === 'abroad'
    },
  },
  methods: {
    goLogin() {
      this.$router.push({
        path: '/account/login',
      })
    },
    geEn(key) {
      const i18n = this.$i18n
      return i18n._getMessages().en[key] || key
    },
    autoPrefix(name) {
      let fun = '$t'
      if (this.abroad) {
        fun = 'geEn'
      }
      return this[fun](name)
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
// @import '~@/styles/common.less';
.personal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: @uni-width;
  margin: 20px auto;
  background: #fff;
  .title {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 40px;
  }
  .des {
    position: relative;
    right: -170px;
    display: flex;
    margin-bottom: 20px;
    font-size: 14px;
    color: #8f8f8f;
    .link {
      color: @uni-text-color-select;
      cursor: pointer;
    }
  }
}
</style>
